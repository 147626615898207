import React from 'react';
import './App.css';  // Garante que o CSS seja aplicado
import logo from './img/Logo.png';
import './styles/img.css';
import './styles/footer.css';
import 'animate.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo" className="img-resize animate__animated animate__fadeIn" /> 
        <footer className="footer animate__animated animate__fadeInDown animate__delay-1s">
        <p>Olá! Obrigado por visitar nossa página. Estamos atualmente em processo de construção para trazer a você a melhor experiência possível</p>
      </footer>
      </header>

    </div>
  );
}

export default App;